import React from 'react'
import Errors from "./errors";
import Hint from "../hint";
import {_t} from '../../../../js/translation';
import {classNames} from "../../tools/react.dom.helpers";

const InputField = (props) => {
    let valueProps = props.controlled ? {value: props.value} : {defaultValue: props.value};

    return (
        <div className={classNames("form-group form-group__input", props.errors && '--error', props.className && props.className)}>
            {props.label &&
                <span className={"form-group__label-wrapper " + (props.hintText ? 'form-group__label--has-hint' : '')}>
                    <label htmlFor={props.name} className={"form-group__label label"}>
                        {props.icon ? <i className={"form-group__label-icon fal " + (props.icon)}/> : ''}
                        {_t(props.label)}
                    </label>
                    {props.hintText ? <Hint title={props.hintTitle} text={props.hintText}/> : ''}
                </span>
            }
            <div className="form-group__input-wrapper">
                <input
                    {...valueProps}
                    onChange={props.onChange}
                    type={props.type || "text"}
                    name={props.name}
                    onKeyPress={props.onKeyPress}
                    autoFocus={props.autoFocus}
                    disabled={props.disabled}
                    required={props.required}
                    id={props.name}
                    step={props.step}
                    placeholder={_t(props.placeholder || props.label)}
                    className={"form-group__input input form-control " + (props.errors ? 'is-invalid' : '')}
                    readOnly={props.readOnly}
                    onClick={props.onClick}
                />

                {props.errors &&
                    <Errors errors={props.errors} customClass="harmonizely-error-basic form-group__input-error"/>}
            </div>
        </div>
    );
};

export default InputField;